import React from 'react';

export const HeartGreenIcon = () => (
  <svg width="16" height="14" viewBox="0 0 16 14" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.9963 1.71183C13.2187 0.932253 12.188 0.456443 11.0903 0.370349C9.99262 0.284256 8.90033 0.593556 8.01074 1.24238C7.07745 0.548204 5.91581 0.233431 4.75973 0.36145C3.60365 0.489468 2.53901 1.05077 1.78021 1.93232C1.02141 2.81387 0.624803 3.95018 0.670266 5.11244C0.715728 6.2747 1.19988 7.37656 2.02522 8.19615L6.58038 12.7586C6.96182 13.134 7.47556 13.3444 8.01074 13.3444C8.54593 13.3444 9.05966 13.134 9.44111 12.7586L13.9963 8.19615C14.8527 7.33446 15.3334 6.1689 15.3334 4.95399C15.3334 3.73908 14.8527 2.57352 13.9963 1.71183Z"
      fill="#6CCF8E"
    />
  </svg>
);

export const HeartRedIcon = () => (
  <svg width="16" height="14" viewBox="0 0 16 14" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.9863 1.68881C13.2081 0.910402 12.1764 0.437137 11.0788 0.355084C9.98115 0.273032 8.89053 0.587642 8.00525 1.2417C7.07266 0.548043 5.91188 0.233506 4.75667 0.361429C3.60145 0.489352 2.53761 1.05023 1.77937 1.93112C1.02114 2.81201 0.624834 3.94748 0.670263 5.10887C0.715692 6.27026 1.19948 7.3713 2.02421 8.19027L7.48484 13.6582C7.55298 13.7269 7.63405 13.7815 7.72337 13.8187C7.81269 13.8559 7.90849 13.875 8.00525 13.875C8.10202 13.875 8.19782 13.8559 8.28714 13.8187C8.37646 13.7815 8.45752 13.7269 8.52566 13.6582L13.9863 8.19027C14.4134 7.76348 14.7522 7.25672 14.9833 6.69894C15.2144 6.14117 15.3334 5.54331 15.3334 4.93954C15.3334 4.33577 15.2144 3.73792 14.9833 3.18014C14.7522 2.62237 14.4134 2.1156 13.9863 1.68881ZM12.9528 7.14945L8.00525 12.097L3.0577 7.14945C2.48995 6.59497 2.15463 5.84511 2.11989 5.05227C2.08516 4.25944 2.35361 3.48313 2.87069 2.88111C3.38777 2.2791 4.11467 1.89656 4.90367 1.81124C5.69266 1.72592 6.48454 1.94422 7.11836 2.42178L5.86498 5.35367C5.82142 5.44916 5.79888 5.5529 5.79888 5.65785C5.79888 5.76281 5.82142 5.86654 5.86498 5.96204C5.91153 6.05742 5.97824 6.14156 6.0605 6.20863C6.14276 6.27571 6.2386 6.32412 6.34141 6.35051L8.37174 6.86359L7.34558 8.98188C7.30305 9.06848 7.27803 9.16262 7.27197 9.2589C7.2659 9.35519 7.2789 9.45173 7.31023 9.54298C7.34155 9.63423 7.39058 9.7184 7.45451 9.79066C7.51844 9.86292 7.596 9.92184 7.68275 9.96406C7.78326 10.0127 7.89357 10.0378 8.00525 10.0374C8.14263 10.0376 8.27731 9.9993 8.39395 9.92672C8.51059 9.85415 8.60448 9.75026 8.66493 9.62689L10.1309 6.69501C10.1778 6.59656 10.2021 6.48888 10.2021 6.37983C10.2021 6.27078 10.1778 6.16311 10.1309 6.06465C10.0824 5.96637 10.0136 5.87957 9.92887 5.81004C9.84418 5.74052 9.74562 5.68988 9.63978 5.66152L7.56547 5.14111L8.62828 2.65634C9.2241 2.08092 10.0241 1.76577 10.8523 1.7802C11.6805 1.79463 12.469 2.13747 13.0444 2.7333C13.6198 3.32912 13.935 4.12912 13.9206 4.95731C13.9061 5.7855 13.5633 6.57404 12.9675 7.14945H12.9528Z"
      fill="#FF5286"
    />
  </svg>
);

export const HeartIcon = () => (
  <svg
    version="1.1"
    width="16"
    height="16"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    fill="currentColor"
    viewBox="0 0 490.4 490.4"
  >
    <g>
      <path
        d="M222.5,453.7c6.1,6.1,14.3,9.5,22.9,9.5c8.5,0,16.9-3.5,22.9-9.5L448,274c27.3-27.3,42.3-63.6,42.4-102.1
  		c0-38.6-15-74.9-42.3-102.2S384.6,27.4,346,27.4c-37.9,0-73.6,14.5-100.7,40.9c-27.2-26.5-63-41.1-101-41.1
  		c-38.5,0-74.7,15-102,42.2C15,96.7,0,133,0,171.6c0,38.5,15.1,74.8,42.4,102.1L222.5,453.7z M59.7,86.8
  		c22.6-22.6,52.7-35.1,84.7-35.1s62.2,12.5,84.9,35.2l7.4,7.4c2.3,2.3,5.4,3.6,8.7,3.6l0,0c3.2,0,6.4-1.3,8.7-3.6l7.2-7.2
  		c22.7-22.7,52.8-35.2,84.9-35.2c32,0,62.1,12.5,84.7,35.1c22.7,22.7,35.1,52.8,35.1,84.8s-12.5,62.1-35.2,84.8L251,436.4
  		c-2.9,2.9-8.2,2.9-11.2,0l-180-180c-22.7-22.7-35.2-52.8-35.2-84.8C24.6,139.6,37.1,109.5,59.7,86.8z"
      />
    </g>
  </svg>
);

export const CrossCircleIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.877075 7.49988C0.877075 3.84219 3.84222 0.877045 7.49991 0.877045C11.1576 0.877045 14.1227 3.84219 14.1227 7.49988C14.1227 11.1575 11.1576 14.1227 7.49991 14.1227C3.84222 14.1227 0.877075 11.1575 0.877075 7.49988ZM7.49991 1.82704C4.36689 1.82704 1.82708 4.36686 1.82708 7.49988C1.82708 10.6329 4.36689 13.1727 7.49991 13.1727C10.6329 13.1727 13.1727 10.6329 13.1727 7.49988C13.1727 4.36686 10.6329 1.82704 7.49991 1.82704ZM9.85358 5.14644C10.0488 5.3417 10.0488 5.65829 9.85358 5.85355L8.20713 7.49999L9.85358 9.14644C10.0488 9.3417 10.0488 9.65829 9.85358 9.85355C9.65832 10.0488 9.34173 10.0488 9.14647 9.85355L7.50002 8.2071L5.85358 9.85355C5.65832 10.0488 5.34173 10.0488 5.14647 9.85355C4.95121 9.65829 4.95121 9.3417 5.14647 9.14644L6.79292 7.49999L5.14647 5.85355C4.95121 5.65829 4.95121 5.3417 5.14647 5.14644C5.34173 4.95118 5.65832 4.95118 5.85358 5.14644L7.50002 6.79289L9.14647 5.14644C9.34173 4.95118 9.65832 4.95118 9.85358 5.14644Z"
      fill="currentColor"
    />
  </svg>
);

export const ExpandIcon = () => {
  return (
    <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 1.16994C10.8126 0.983692 10.5592 0.87915 10.295 0.87915C10.0308 0.87915 9.77737 0.983692 9.59001 1.16994L6.00001 4.70994L2.46001 1.16994C2.27265 0.983692 2.0192 0.87915 1.75501 0.87915C1.49082 0.87915 1.23737 0.983692 1.05001 1.16994C0.956281 1.26291 0.881887 1.37351 0.831118 1.49537C0.780349 1.61723 0.754211 1.74793 0.754211 1.87994C0.754211 2.01195 0.780349 2.14266 0.831118 2.26452C0.881887 2.38638 0.956281 2.49698 1.05001 2.58994L5.29001 6.82994C5.38297 6.92367 5.49357 6.99806 5.61543 7.04883C5.73729 7.0996 5.868 7.12574 6.00001 7.12574C6.13202 7.12574 6.26273 7.0996 6.38459 7.04883C6.50645 6.99806 6.61705 6.92367 6.71001 6.82994L11 2.58994C11.0937 2.49698 11.1681 2.38638 11.2189 2.26452C11.2697 2.14266 11.2958 2.01195 11.2958 1.87994C11.2958 1.74793 11.2697 1.61723 11.2189 1.49537C11.1681 1.37351 11.0937 1.26291 11 1.16994Z"
        fill="currentColor"
        fillOpacity="0.65"
      />
    </svg>
  );
};

interface IsOncallIconProps {
  className: string;
  width?: number;
  height?: number;
}

export const IsOncallIcon = (props: IsOncallIconProps) => {
  const { className } = props;
  const width = props.width || 17;
  const height = props.height || 16;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8.72021" cy="8" r="7.5" fill="#6CCF8E" stroke="currentColor" />
      <path
        d="M6.91558 9.7903C7.32816 10.2082 7.76482 10.584 8.22558 10.9177C8.68634 11.2514 9.14709 11.5145 9.60785 11.7069C10.0686 11.9023 10.5038 12 10.9133 12C11.1934 12 11.4539 11.9504 11.6948 11.8512C11.9357 11.752 12.1541 11.5941 12.3498 11.3777C12.4612 11.2514 12.5501 11.1161 12.6163 10.9718C12.6856 10.8275 12.7202 10.6817 12.7202 10.5344C12.7202 10.4262 12.6976 10.3209 12.6525 10.2187C12.6073 10.1165 12.532 10.0293 12.4266 9.95716L11.1121 9.02818C11.0097 8.95303 10.9148 8.89891 10.8275 8.86584C10.7432 8.83277 10.6619 8.81623 10.5836 8.81623C10.4872 8.81623 10.3923 8.84329 10.299 8.89741C10.2086 8.94852 10.1153 9.02368 10.0189 9.12289L9.70723 9.42052C9.66507 9.46561 9.61086 9.48816 9.54461 9.48816C9.51148 9.48816 9.47986 9.48365 9.44975 9.47463C9.41963 9.46261 9.39403 9.45209 9.37295 9.44307C9.23744 9.37091 9.06428 9.24615 8.85347 9.06877C8.64568 8.89139 8.43638 8.69748 8.22558 8.48703C8.01176 8.27659 7.81602 8.06614 7.63834 7.85569C7.46066 7.64525 7.33719 7.47388 7.26793 7.3416C7.25588 7.31755 7.24384 7.292 7.23179 7.26494C7.22276 7.23487 7.21824 7.2018 7.21824 7.16573C7.21824 7.10259 7.24082 7.04998 7.286 7.00789L7.58865 6.70124C7.68502 6.60203 7.76031 6.50733 7.81451 6.41714C7.86872 6.32394 7.89582 6.22773 7.89582 6.12852C7.89582 6.05036 7.87775 5.96918 7.84162 5.88501C7.80849 5.79782 7.75579 5.70312 7.68351 5.6009L6.75748 4.30665C6.68521 4.20143 6.59637 4.12477 6.49097 4.07666C6.38556 4.02555 6.27263 4 6.15217 4C5.85705 4 5.5815 4.12326 5.32552 4.36979C5.11472 4.57121 4.96113 4.79369 4.86477 5.0372C4.7684 5.28072 4.72021 5.53927 4.72021 5.81285C4.72021 6.22473 4.81508 6.65915 5.0048 7.11612C5.19753 7.57309 5.45953 8.03006 5.7908 8.48703C6.12206 8.941 6.49699 9.37542 6.91558 9.7903Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const TelegramColorIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48px" height="48px">
      <path fill="#29b6f6" d="M24 4A20 20 0 1 0 24 44A20 20 0 1 0 24 4Z" />
      <path
        fill="#fff"
        d="M33.95,15l-3.746,19.126c0,0-0.161,0.874-1.245,0.874c-0.576,0-0.873-0.274-0.873-0.274l-8.114-6.733 l-3.97-2.001l-5.095-1.355c0,0-0.907-0.262-0.907-1.012c0-0.625,0.933-0.923,0.933-0.923l21.316-8.468 c-0.001-0.001,0.651-0.235,1.126-0.234C33.667,14,34,14.125,34,14.5C34,14.75,33.95,15,33.95,15z"
      />
      <path
        fill="#b0bec5"
        d="M23,30.505l-3.426,3.374c0,0-0.149,0.115-0.348,0.12c-0.069,0.002-0.143-0.009-0.219-0.043 l0.964-5.965L23,30.505z"
      />
      <path
        fill="#cfd8dc"
        d="M29.897,18.196c-0.169-0.22-0.481-0.26-0.701-0.093L16,26c0,0,2.106,5.892,2.427,6.912 c0.322,1.021,0.58,1.045,0.58,1.045l0.964-5.965l9.832-9.096C30.023,18.729,30.064,18.416,29.897,18.196z"
      />
    </svg>
  );
};

export const SlackNewIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48px" height="48px">
    <path
      fill="#33d375"
      d="M33,8c0-2.209-1.791-4-4-4s-4,1.791-4,4c0,1.254,0,9.741,0,11c0,2.209,1.791,4,4,4s4-1.791,4-4	C33,17.741,33,9.254,33,8z"
    />
    <path
      fill="#33d375"
      d="M43,19c0,2.209-1.791,4-4,4c-1.195,0-4,0-4,0s0-2.986,0-4c0-2.209,1.791-4,4-4S43,16.791,43,19z"
    />
    <path
      fill="#40c4ff"
      d="M8,14c-2.209,0-4,1.791-4,4s1.791,4,4,4c1.254,0,9.741,0,11,0c2.209,0,4-1.791,4-4s-1.791-4-4-4	C17.741,14,9.254,14,8,14z"
    />
    <path
      fill="#40c4ff"
      d="M19,4c2.209,0,4,1.791,4,4c0,1.195,0,4,0,4s-2.986,0-4,0c-2.209,0-4-1.791-4-4S16.791,4,19,4z"
    />
    <path
      fill="#e91e63"
      d="M14,39.006C14,41.212,15.791,43,18,43s4-1.788,4-3.994c0-1.252,0-9.727,0-10.984	c0-2.206-1.791-3.994-4-3.994s-4,1.788-4,3.994C14,29.279,14,37.754,14,39.006z"
    />
    <path
      fill="#e91e63"
      d="M4,28.022c0-2.206,1.791-3.994,4-3.994c1.195,0,4,0,4,0s0,2.981,0,3.994c0,2.206-1.791,3.994-4,3.994	S4,30.228,4,28.022z"
    />
    <path
      fill="#ffc107"
      d="M39,33c2.209,0,4-1.791,4-4s-1.791-4-4-4c-1.254,0-9.741,0-11,0c-2.209,0-4,1.791-4,4s1.791,4,4,4	C29.258,33,37.746,33,39,33z"
    />
    <path
      fill="#ffc107"
      d="M28,43c-2.209,0-4-1.791-4-4c0-1.195,0-4,0-4s2.986,0,4,0c2.209,0,4,1.791,4,4S30.209,43,28,43z"
    />
  </svg>
);
